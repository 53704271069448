const EN_ITEM_MESSAGE_CODE = {
    null: "",
    "": "",
    "TYPE.ERROR": "Error",
    "SM210MSG003":"整数値を入力してください。",
    'SM910MSG004': 'The length of ARM must be less than or equal to 100 characters.',

    // Button
    "CM-BUTTON-ADD": "Add",
    "CM-BUTTON-CREATE": "Create",
    "CM-BUTTON-REGISTER": "Register",
    "CM-BUTTON-CLEAR-ALL": "Clear All",
    "CM-BUTTON-SEARCH": "Search",
    "CM-BUTTON-SEND": "Send",
    "CM-BUTTON-YES": "Yes",
    "CM-BUTTON-NO": "No",
    "CM-BUTTON-IMPORT": "Import",
    "CM-BUTTON-UPLOAD": "Upload",
    "CM-BUTTON-DOWNLOAD": "Download",
    "CM-BUTTON-SELECT": "Select",
    "CM-BUTTON-SAVE": "Save",
    "CM-BUTTON-CANCEL": "Cancel",
    "CM-BUTTON-CLOSE": "Close",
    "CM-BUTTON-AUDIT-TRAIL": "Audit Trail",
    "CM-BUTTON-BACK-TO-LOGIN": "Back to Login",
    "CM-BUTTON-LOGOUT": "Logout",
    "CM-BUTTON-LOGIN": "Login",
    "CM-BUTTON-PASSWORD-INITIALIZATION": "Reset password initialization",
    "CM-BUTTON-DELETE": "Delete",
    "CM-BUTTON-RESTORE": "Restore",
    "SB-BUTTON-OPEN-STUDY": "Open",
    "SB-BUTTON-CLOSE-STUDY": "Close",
    "SB-BUTTON-ADD-BLOCK": "Add new Block",
    "SB-BUTTON-ADD-QUESTION": "Add new question",
    "SB-BUTTON-ADD-ITEM": "Add new item",
    "SB-BUTTON-PREVIEW": "Preview",
    "SB-BUTTON-CRF-PAGE": "CRF Page",
    "SB-BUTTON-PROPERY": "Property",
    "SB-BUTTON-CLONE-STUDY": "CLONE",
    "SB-BUTTON-RELEASE-STUDY": "RELEASE",

    // Label
    "CM-LABEL-TODAY": "Today",
    "CM-LABEL-REQUIRED": "Required",
    "CM-LABEL-REQUIRED-FIELD": "※ is a required field.",
    "CM-LABEL-PER-PAGE": "per page",
    "CM-LABEL-DISPLAY-RECORD-FROM-TO": "{x} - {y} of {z} records",
    "CM-LABEL-FIRST-PAGE": "First",
    "CM-LABEL-LAST-PAGE": "Last",
    "CM-LABEL-PREVIOUS-PAGE": "Previous",
    "CM-LABEL-NEXT-PAGE": "Next",
    "SB-LABEL-UPDATED-USER": "Updated User",
    "SB-LABEL-UPDATED-TIME": "Updated Time",
    "CM-LABEL-AUDIT": "Audit",
    "CM-LABEL-AUDIT-TRAIL": "Audit Trail",
    "CM-LABEL-AUDIT-TABLE-TITLE": "{item} Audit",
    "CM-LABEL-ORDINAL-NUMBER": "No.",
    "SB-LABEL-FILE": "File",
    "CM-LABEL-STATUS-01": "Status",
    "SB-LABEL-ACTION": "Action",
    "CM-LABEL-DELETE": "Delete",
    "CM-LABEL-LABEL": "Label",
    "CM-LABEL-CODE": "Code",
    "CM-LABEL-MINUTE-UNIT": "Minutes",
    "CM-LABEL-VERSION": "Version",
    "SB-LABEL-VERSION": "Version",
    "SB-LABEL-ID": "ID",
    "SB-LABEL-VISIT-ID": "Visit ID",
    "SB-LABEL-PAGE-ID": "Page ID",
    "SB-LABEL-BLOCK-ID": "Block ID",
    "SB-LABEL-ITEM-ID": "Item ID",
    "CM-LABEL-RESET-PWD-INITIALIZATION": "Password Initialization",
    "CM-LABEL-CONFIRMATION": "Confirmation",
    "CM-LABEL-EMAIL-02": "E-mail",
    "CM-LABEL-EXCEL-UPLOAD-MODAL": "Import Excel file",
    "CM-LABEL-USER-NAME": "User Name",
    "CM-LABEL-ERROR": "Error",
    "CM-LABEL-ALERT-MESSAGE": "Message",

    // Authen
    "SB-LABEL-LOGIN": "Login",
    "CM-LABEL-LOGIN-ID": "User ID",
    "CM-LABEL-PASSWORD": "Password",
    "CM-LABEL-FORGOT-PASSWORD": "Forgot Password",
    "CM-LABEL-FORGOT-USER-ID": "Forgot User ID",
    "SB-LABEL-PASSWORD-SETTING": "Password Setting",
    "CM-LABEL-EMAIL-01": "E-mail",
    "CM-LABEL-SECURITY-QUESTION": "Security Question",
    "CM-LABEL-SECURITY-ANSWER-01": "Security Answer",
    "CM-LABEL-NEW-PASSWORD": "New Password",
    "CM-LABEL-CONFIRM-NEW-PASSWORD": "Confirm New Password",

    // Study
    "CM-LABEL-STUDY-LIST": "Study List",
    "SB-LABEL-STUDY-DETAIL": "Study Details",
    "SB-LABEL-CREATE-STUDY": "New Study Registration",
    "CM-LABEL-STUDY-NAME": "Study Name",
    "CM-LABEL-PROTOCOL-NO": "Protocol No.",
    "CM-LABEL-ALIAS": "Alternate Study Identifier",
    "CM-LABEL-STUDY-ID": "Study ID",
    "CM-LABEL-SYSTEM-LINK": "Integrated Systems",
    "SB-LABEL-STUDY": "Study",
    "CM-LABEL-OPEN": "Open",
    "CM-LABEL-CLOSE": "Close",
    "SB-LABEL-CDMS": "CDMS",
    "SB-LABEL-PRO": "PRO",
    "SB-LABEL-ECONSENT": "eConsent",
    "CM-LABEL-IWRS": "IWRS",
    "SB-LABEL-STUDY-MANAGEMENT-SYSTEM": "Study Management System",

    // EDC Setting
    "SB-LABEL-EDC-SETTING": "EDC Setting",
    "CM-LABEL-ETRAINING": "e-Training",
    "SB-LABEL-DIGITS-PER-SITE-CODE": "Digits per site code",
    "SB-LABEL-SCREENING-NUMBER": "Screening Number",
    "SB-LABEL-ENROLLMENT-NUMBER": "Enrollment Number",
    "SB-LABEL-LEVELS-CONTRACT-CASES":
        "Set maximum limit for the number of registered subjects",
    "SB-LABEL-AUTOMATIC-LOGOUT-TIME": "Automatic Logout Time",
    "SB-LABEL-VALUE": "Value",
    "SB-LABEL-DOCUMENT-UPLOAD-MODAL": "Upload Document",
    "CM-LABEL-DOCUMENT-NAME": "Document Name",
    "SB-LABEL-DOCUMENT-DESCRIPTION": "Document Description",
    "SB-LABEL-UPDATED-DATE": "Date of Modification",
    "SB-LABEL-DOWNLOAD": "Download",
    "SB-LABEL-DOCUMENT": "Document",

    // Role & priviledge
    "SB-LABEL-ROLE-PRIVILEGE-SETTING": "Role & Privilege Setting",
    "SB-LABEL-ROLE-PRIVILEGE": "Role & Privilege",
    "CM-LABEL-ROLE": "Role",
    "CM-LABEL-PRIVILEGE": "Privilege",
    "CM-LABEL-SETTING": "Setting",
    "SB-LABEL-ROLE-LIST": "Roles",
    "SB-LABEL-PRIVILEGE-LIST": "Privileges",
    "SB-LABEL-ENABLE": "Enable",
    "SB-LABEL-DESCRIPTION": "Description",
    "SB-LABEL-CATEGORY": "Category",
    "CM-LABEL-DETAIL": "Description",
    "SB-LABEL-PRIVILEGE-CRF-VIEW": "CRF_VIEW",
    "SB-LABEL-PRIVILEGE-CRF-EDIT": "CRF_EDIT",
    "SB-LABEL-PRIVILEGE-FREEZE-VIEW": "FREEZE_VIEW",
    "SB-LABEL-PRIVILEGE-FREEZE-EDIT": "FREEZE_EDIT",
    "SB-LABEL-PRIVILEGE-SDV-VIEW": "SDV_VIEW",
    "SB-LABEL-PRIVILEGE-SDV-EDIT": "SDV_EDIT",
    "SB-LABEL-PRIVILEGE-REVIEW-VIEW": "REVIEW_VIEW",
    "SB-LABEL-PRIVILEGE-REVIEW-EDIT": "REVIEW_EDIT",
    "SB-LABEL-PRIVILEGE-LOCK-VIEW": "LOCK_VIEW",
    "SB-LABEL-PRIVILEGE-LOCK-EDIT": "LOCK_EDIT",
    "SB-LABEL-PRIVILEGE-DB-LOCK-VIEW": "DB_LOCK_VIEW",
    "SB-LABEL-PRIVILEGE-DB-LOCK-EDIT": "DB_LOCK_EDIT",
    "SB-LABEL-PRIVILEGE-SIGNATURE-VIEW": "SIGNATURE_VIEW",
    "SB-LABEL-PRIVILEGE-SIGNATURE-EDIT": "SIGNATURE_EDIT",
    "SB-LABEL-PRIVILEGE-ECS-RE-EXECUTE": "ECS_RE_EXECUTE",
    "SB-LABEL-PRIVILEGE-CASE-REGISTER": "CASE_REGISTER",
    "SB-LABEL-PRIVILEGE-CASE-DELETE": "CASE_DELETE",
    "SB-LABEL-PRIVILEGE-PAGE-INITIAL": "PAGE_INITIAL",
    "SB-LABEL-PRIVILEGE-ADMIN-VIEW": "ADMIN_VIEW",
    "SB-LABEL-PRIVILEGE-ADMIN-EDIT": "ADMIN_EDIT",
    "SB-LABEL-PRIVILEGE-QUERY-ISSUE": "QUERY_ISSUE",
    "SB-LABEL-PRIVILEGE-QUERY-VIEW": "QUERY_VIEW",
    "SB-LABEL-PRIVILEGE-QUERY-ANSWER": "QUERY_ANSWER",
    "SB-LABEL-PRIVILEGE-COMMENT-ADD": "COMMENT_ADD",
    "SB-LABEL-PRIVILEGE-DATASET-EXPORT": "DATASET_EXPORT",
    "SB-LABEL-PRIVILEGE-ASSIGNMENT-IMPORT": "ASSIGNMENT_IMPORT",
    "SB-LABEL-PRIVILEGE-ASSIGNMENT-VIEW": "ASSIGNMENT_VIEW",
    "SB-LABEL-PRIVILEGE-USER-REPORT-EXPORT": "USER_REPORT_EXPORT",
    "SB-LABEL-PRIVILEGE-USER-REPORT-EDIT": "USER_REPORT_EDIT",
    "SB-LABEL-PRIVILEGE-IP-NO-DELIVERY": "IP_NO_DELIVERY",
    "SB-LABEL-PRIVILEGE-LIST-UPLOAD": "LIST_UPLOAD",
    "SB-LABEL-PRIVILEGE-EMERGENCY-KEY-OPEN": "EMERGENCY_KEY_OPEN",
    "SB-LABEL-PRIVILEGE-EMERGENCY-KEY-APPROVE": "EMERGENCY_KEY_APPROVE",
    "SB-LABEL-PRIVILEGE-CRF-IMPORT-DATA": "CRF_IMPORT_DATA",
    "SB-LABEL-PRIVILEGE-LINK-ECONSENT": "LINK_ECONSENT",
    "SB-LABEL-PRIVILEGE-ECONSENT-HISTORY-VIEW": "ECONSENT_HISTORY_VIEW",
    "SB-LABEL-PRIVILEGE-ETRAINING-CERT-DOWNLOAD": "ETRAINING_CERT_DOWNLOAD",
    "SB-LABEL-PRIVILEGE-ACTIVE_EMERGENCY": "ACTIVE_EMERGENCY",
    "SB-LABEL-PRIVILEGE-EMERGENCY-HISTORY-VIEW": "EMERGENCY_HISTORY_VIEW",
    "SB-LABEL-CATEGORY-CRF": "CRF",
    "SB-LABEL-CATEGORY-FREEZE": "FREEZE",
    "SB-LABEL-CATEGORY-SDV": "SDV",
    "SB-LABEL-CATEGORY-REVIEW": "REVIEW",
    "SB-LABEL-CATEGORY-LOCK": "LOCK",
    "SB-LABEL-CATEGORY-DB-LOCK": "DB_LOCK",
    "SB-LABEL-CATEGORY-SIGNATURE": "SIGNATURE",
    "SB-LABEL-CATEGORY-ECS-RE": "ECS_RE",
    "SB-LABEL-CATEGORY-CASE": "CASE",
    "SB-LABEL-CATEGORY-PAGE": "PAGE",
    "SB-LABEL-CATEGORY-ADMIN": "ADMIN",
    "SB-LABEL-CATEGORY-QUERY": "QUERY",
    "SB-LABEL-CATEGORY-COMMENT": "COMMENT",
    "SB-LABEL-CATEGORY-DATASET": "DATASET",
    "SB-LABEL-CATEGORY-ASSIGNMENT": "ASSIGNMENT",
    "SB-LABEL-CATEGORY-USER-REPORT": "USER_REPORT",
    "SB-LABEL-CATEGORY-DELIVERY": "DELIVERY",
    "SB-LABEL-CATEGORY-LIST-UPLOAD": "LIST_UPLOAD",
    "SB-LABEL-CATEGORY-BLIND": "BLIND",
    "SB-LABEL-CATEGORY-DATA-IMPORT": "DATA_IMPORT",
    "SB-LABEL-CATEGORY-ECONSENT": "ECONSENT",
    "SB-LABEL-CATEGORY-E-TRAINING": "E-TRAINING",
    "SB-LABEL-DESCRIPTION-CRF-VIEW":
        "Able to access and view input data in CRF",
    "SB-LABEL-DESCRIPTION-CRF-EDIT": "Able to access and enter data in CRF",
    "SB-LABEL-DESCRIPTION-FREEZE-VIEW":
        "Able to view the Freeze flag of objects which got freezed",
    "SB-LABEL-DESCRIPTION-FREEZE-EDIT": "Able to Freeze the question",
    "SB-LABEL-DESCRIPTION-SDV-VIEW":
        "Able to view the SDV flag of objects which got SDVed",
    "SB-LABEL-DESCRIPTION-SDV-EDIT": "Able to SDV the question",
    "SB-LABEL-DESCRIPTION-REVIEW-VIEW":
        "Able to view the Review flag of objects which got reviewed",
    "SB-LABEL-DESCRIPTION-REVIEW-EDIT": "Able to Review the question",
    "SB-LABEL-DESCRIPTION-LOCK-VIEW":
        "Able to view the Lock flag of objects which got locked",
    "SB-LABEL-DESCRIPTION-LOCK-EDIT": "Able to Lock the question",
    "SB-LABEL-DESCRIPTION-DB-LOCK-VIEW":
        "Able to view the Data Base Lock flag after DB is locked",
    "SB-LABEL-DESCRIPTION-DB-LOCK-EDIT": "Able to do Data Base Lock",
    "SB-LABEL-DESCRIPTION-SIGNATURE-VIEW": "Able to view e-signature status",
    "SB-LABEL-DESCRIPTION-SIGNATURE-EDIT": "Able to e-sign",
    "SB-LABEL-DESCRIPTION-ECS-RE-EXECUTE": "Able to re-execute the Editcheck",
    "SB-LABEL-DESCRIPTION-CASE-REGISTER": "Able to add Subject",
    "SB-LABEL-DESCRIPTION-CASE-DELETE": "Able to delete Subject",
    "SB-LABEL-DESCRIPTION-PAGE-INITIAL": "Able to sign the page",
    "SB-LABEL-DESCRIPTION-ADMIN-VIEW": "Able to view Admin module",
    "SB-LABEL-DESCRIPTION-ADMIN-EDIT":
        "Able to add account and set role in User Management page of Admin module",
    "SB-LABEL-DESCRIPTION-QUERY-ISSUE": "Able to create, cancel, close Query",
    "SB-LABEL-DESCRIPTION-QUERY-VIEW": "Able to view Query",
    "SB-LABEL-DESCRIPTION-QUERY-ANSWER": "Able to answer Query",
    "SB-LABEL-DESCRIPTION-COMMENT-ADD": "Able to add comment to each Item",
    "SB-LABEL-DESCRIPTION-DATASET-EXPORT":
        "Able to export input data as Dataset",
    "SB-LABEL-DESCRIPTION-ASSIGNMENT-IMPORT":
        "Able to upload randomization list to database",
    "SB-LABEL-DESCRIPTION-ASSIGNMENT-VIEW":
        "Able to view the uploaded randomization list",
    "SB-LABEL-DESCRIPTION-USER-REPORT-EXPORT": "Able to export report",
    "SB-LABEL-DESCRIPTION-USER-REPORT-EDIT":
        "Able to configure report to export",
    "SB-LABEL-DESCRIPTION-IP-NO-DELIVERY": "Able to issue study medication ID",
    "SB-LABEL-DESCRIPTION-LIST-UPLOAD": "Able to upload Drug list",
    "SB-LABEL-DESCRIPTION-EMERGENCY-KEY-OPEN":
        "Able to send a Emergency Key Open request",
    "SB-LABEL-DESCRIPTION-EMERGENCY-KEY-APPROVE":
        "Able to approve a Emergency Key Open request",
    "SB-LABEL-DESCRIPTION-CRF-IMPORT-DATA": "Able to import data for CRF Page",
    "SB-LABEL-DESCRIPTION-LINK-ECONSENT": "Able to link to eConsent",
    "SB-LABEL-DESCRIPTION-ECONSENT-HISTORY-VIEW":
        "Able to view eConsent history",
    "SB-LABEL-DESCRIPTION-ETRAINING-CERT-DOWNLOAD":
        "Able to dowload system e-training certification",
    "SB-LABEL-DESCRIPTION-ACTIVE-EMERGENCY": "",
    "SB-LABEL-DESCRIPTION-EMERGENCY-HISTORY-VIEW": "",
    "SB-LABEL-ENTRY": "Entry",
    "SB-LABEL-CYCLE-AND-VISIT": "Cycle&Visit",
    "SB-LABEL-CYCLE-ID": "Cycle ID",
    "SB-LABEL-REPEAT": "Repeat",
    "SB-LABEL-CYCLE-CODE":"Cycle Code",
    "SB-LABEL-CYCLE":"Cycle",
    "SB-LABEL-VISIT-CODE":"Visit Code",
    "SB-LABEL-VISIT":"Visit",
    "SB-LABEL-VISIBLE": "Visible",

    // Schedule
    "SB-LABEL-SCHEDULE": "Schedule",
    "SB-LABEL-DIARY-SETTING": "Schedule Setting",
    "SB-LABEL-DIARY-START-FROM": "Start survey from",
    "SB-LABEL-DIARY-DELAY": "Survey activation after",
    "SB-LABEL-DIARY-INPUT-PERIOD": "Data entry period",
    "SB-LABEL-DIARY-INPUT-INTERVAL": "Data entry Interval",
    "SB-LABEL-DIARY-NUMBER-OF-ENTRY-PER-DAY": "Number of entries per day",
    "SB-LABEL-DIARY-ENTRY-DEADLINE": "Entry deadline",
    "SB-LABEL-DIARY-NOTIFICATION": "Notification",
    "SB-LABEL-DIARY-NOTIFICATION-DELIVERY-TIME": "Notification Delivery time",
    "SB-LABEL-DIARY-DATA-ENTRY-NOTIFICATION": "Data entry notification",
    "SB-LABEL-DIARY-ENTRY-EXPIRY-NOTIFICATION":
        "Data entry time expiry notification",
    "SB-LABEL-DIARY-SNOOZE": "Snooze",
    "SB-LABEL-DIARY-REPEAT": "Repeat",
    "SB-LABEL-DIARY-NOTIFICATION-INTERVAL": "Interval",
    "SB-LABEL-DIARY-HOUR-UNIT": "Hour(s)",
    "SB-LABEL-DIARY-MINUTE-UNIT": "Minute(s)",
    "SB-LABEL-DIARY-TIMES-UNIT": "Time(s)",
    "SB-LABEL-DIARY-VISIT-DATE": "visit date",
    "SB-LABEL-DIARY-DAY-UNIT": "day(s)",

    // CRF Group
    "SB-LABEL-CRF-GROUP": "CRF Group",
    "SB-LABEL-CRF-GROUP-ID": "Group ID",
    "SB-LABEL-CRF-GROUP-TYPE": "Type",
    "SB-LABEL-SEQ-NO": "Sequence Number",

    // CRF page
    "SB-LABEL-CRF-PAGE": "CRF Page",
    "SB-LABEL-CRF-PAGE-EDITOR": "Page Editor ({group_id_text} - {crf_version})",
    "CM-LABEL-PAGE": "Page",
    "SB-LABEL-CRF-GROUP-NAME": "CRF Group Name",
    "SB-LABEL-NUMBER-OF-LINE": "Number of log Lines",
    "SB-LABEL-CREATE-BLOCK": "Create Block",
    "SB-LABEL-EDIT-BLOCK": "Edit Block ({block_id_text} - {crf_version})",
    "SB-LABEL-CREATE-QUESTION": "Create Question",
    "SB-LABEL-EDIT-QUESTION": "Edit Question ({question_id_text} - {crf_version})",
    "SB-LABEL-CREATE-ITEM": "Create Item",
    "SB-LABEL-EDIT-ITEM": "Edit Item ({item_id_text} - {crf_version})",
    "SB-LABEL-DOMAIN-ID": "Domain ID",
    "SB-LABEL-ITEM-LAYOUT": "Layout",
    "SB-LABEL-ITEM-CODELIST": "CodeList",
    "SB-LABEL-ITEM-DATA-TYPE": "Data Type",
    "SB-LABEL-ITEM-DATA-LENGTH": "Data Length",
    "SB-LABEL-ITEM-REQUIRED-SETTING": "Required Item",
    "SB-LABEL-ITEM-MAX-VALUE": "Maximum value",
    "SB-LABEL-ITEM-MIN-VALUE": "Minimum value",
    "SB-LABEL-CRF-PAGE-PREVIEW": "Preview",
    "SB-LABEL-CRF-PAGE-CUSTOMIZATION-GUIDE":
        "※Please save the page after changing the preview screen",

    // Property
    "SB-LABEL-PROPERTY": "PROPERTY",
    "SB-LABEL-PROPERTY-TYPE": "Property Type",
    "SB-LABEL-PROPERTY-DATA": "Property Data",
    "SB-LABEL-PROPERTY-DISABLE-INPUT": "Disable input",
    "SB-LABEL-PROPERTY-SET-DEFAULT-VALUE": "Set default value",
    "SB-LABEL-PROPERTY-SET-ABOVE-UNIT": "Set unit position above the item",
    "SB-LABEL-PROPERTY-SET-BELOW-UNIT": "Set unit below the item",
    "SB-LABEL-PROPERTY-SET-LEFT-UNIT": "Set unit left to the item",
    "SB-LABEL-PROPERTY-SET-RIGHT-UNIT": "Set unit right to the item",
    "SB-LABEL-PROPERTY-EXCLUDE": "Exclude only specified visits",
    "SB-LABEL-PROPERTY-SCRNUM": "Enter screening number",
    "SB-LABEL-PROPERTY-ENRNUM": "Enter enrollment number",
    "SB-LABEL-PROPERTY-RANDOMIZE-CODE": "Generate the Randomization code",
    "SB-LABEL-PROPERTY-GENERATE-ARM": "Generate arm information",
    "SB-LABEL-PROPERTY-GENERATE-IP-NO": "Generate IP Number",
    "SB-LABEL-PROPERTY-GENERATE-STRATIFICATION-GROUP-NO":
        "Generate the Stratification Group Number",
    "SB-LABEL-PROPERTY-HIDE-ITEM": "Hide item",
    "SB-LABEL-PROPERTY-DATA-TEXT": "Text",
    "SB-LABEL-PROPERTY-DATA-VISIT-ID": "VISIT ID",
    "SB-LABEL-PROPERTY-DATA-GROUP-ID": "VISIT ID.CRF GROUP ID",
    "SB-LABEL-PROPERTY-DATA-NUMBER": "Number",
    "SB-LABEL-PROPERTY-DATA-YES-NO": "Y/N",
    "SB-LABEL-PROPERTY-REGISTRATION-DATE": "Registration Date",
    "SB-LABEL-PROPERTY-ACTIVATE-EPRO": "Activate ePRO",
    "SB-LABEL-PROPERTY-GET-DATA-FROM-DEVICE": "Get data from the device",

    // Codelist
    "SB-LABEL-CODELIST": "CODE LIST",
    "SB-LABEL-CODELIST-VALUE": "Code List Value",
    "SB-LABEL-CODELIST-NAME": "Name",
    "SB-LABEL-CODELIST-UI-VALUE": "UI Value",
    "SB-LABEL-CODELIST-DB-VALUE": "DB Value",
    "SB-LABEL-CODELIST-ITEM-COUNT": "Item Count",
    "SB-LABEL-CREATE-CODELIST": "Create CodeList",
    "SB-LABEL-EDIT-CODELIST": "Edit CodeList",
    "SB-LABEL-CODELIST-HIDE-LABEL": "Hide Label",
    "SB-LABEL-CODELIST-EMPTY": "EMPTY",
    "SB-LABEL-CODELIST-NOT-SELECTED": "[NotSelected]",
    "SB-LABEL-CODELIST-SETTING": "Code List Setting",

    // ECS
    "SB-LABEL-ECS-LIST": "ECS",
    "SB-LABEL-ECS-LOGIC-ID": "Logic ID",
    "SB-LABEL-ECS-TYPE": "Type",
    "SB-LABEL-ECS-MESSAGE": "Message",
    "SB-LABEL-ECS-FORCE-SAVE": "Force Save",
    "SB-LABEL-ECS-COPY": "Copy",
    "SB-LABEL-ECS-LINE": "Line",
    "SB-LABEL-ECS-CHECK-LOGIC": "Check Logic",
    "SB-LABEL-ECS-TO": "To",
    "SB-LABEL-ECS-SUBJECT": "Subject",
    "SB-LABEL-ECS-BODY": "Body",
    "SB-LABEL-CREATE-ECS": "Create ECS",
    "SB-LABEL-EDIT-ECS": "Edit ECS",

    // Version
    "SB-LABEL-CRF-VERSION": "CRF Version",
    "SB-LABEL-ORIGINAL-CRF-VERSION": "Original CRF Version",
    "SB-LABEL-CLONE-CRF": "Clone CRF",
    "SB-LABEL-ECS-VERSION": "ECS Version",
    "SB-LABEL-ORIGINAL-ECS-VERSION": "Original ECS Version",
    "SB-LABEL-CLONE-ECS": "Clone ECS",
    "SB-LABEL-CREATED-USER": "User",
    "SB-LABEL-CREATED-TIME": "Created Time",
    "SB-LABEL-CREATE-VERSION": "Create Version",

    // User
    "SB-LABEL-ADMIN": "Admin",
    "SB-LABEL-PRIVILEGE": "Privilege",
    "SB-LABEL-START-DATE": "Start Date",
    "SB-LABEL-END-DATE": "End Date",
    "SB-LABEL-USER": "USER",
    "SB-LABEL-USER-01": "user",
    "SB-LABEL-ADD-USER": "Add User",

    // Release
    "SB-LABEL-RELEASE": "RELEASE",
    "SB-LABEL-RELEASE-ID": "Release ID",
    "SB-LABEL-RELEASE-NAME": "Release Name",
    "SB-LABEL-RELEASE-DATE": "Release Date",

    // CLONE
    "SB-LABEL-STUDY-ID": "Study ID",
    "SB-LABEL-STUDY-CLONE": "STUDY CLONE",
    "SB-LABEL-STUDY-CLONE-MESSAGE": "Message",

    // IWRS
    "CM-LABEL-IWRS": "IWRS",
    "SB-LABEL-IWRS-TYPE": "TYPE",
    "SB-LABEL-IWRS-STRATIFICATION-TYPE": "Stratification Type",
    "SB-LABEL-IWRS-BLIND": "Blind",
    "SB-LABEL-IWRS-UNBLIND-SETTING": "Emergency Unblinding setting",
    "SB-LABEL-IWRS-UNBLIND-TYPE": "Emergency Unblinding type",
    "SB-LABEL-IWRS-IP-MANAGEMENT": "IP Management",
    "CM-LABEL-IWRS-ARM": "ARM",
    "SB-LABEL-IWRS-ARM": "ARM",
    "SB-LABEL-IWRS-STATIC-STRATIFICATION": "STATIC STRATIFICATION",
    "SB-LABEL-IWRS-STRATIFICATION": "Stratification",
    "SB-LABEL-IWRS-STRATIFICATION-INFOR": "Stratification Information",
    "SB-LABEL-IWRS-STRATIFICATION-GROUP-NO": "Stratification group number",
    "SB-LABEL-IWRS-STRATIFICATION-NO": "Stratification number",
    "SB-LABEL-IWRS-STRATIFICATION-GROUP": "Stratification Group",
    "SB-LABEL-IWRS-DYNAMIC-STRATIFICATION": "DYNAMIC STRATIFICATION",

    // ePRO
    "SB-EPRO-CONTACT-ADDRESS": "Contact address",
    "SB-EPRO-FAQ": "FAQ",

    // Super Admin - USER
    "SB-LABEL-USER-MANAGEMENT": "User Management",
    "SB-LABEL-USER-REGISTRATION": "User Registration",
    "SB-LABEL-USER-MODIFICATION": "User Modification",
    "SB-LABEL-LOGIN-ID": "Login ID",

    // Super Admin - Notification
    "SB-LABEL-NOTIFICATION": "Notification",
    "SB-LABEL-CREATE-NOTIFICATION": "Create Notification",
    "SB-LABEL-EDIT-NOTIFICATION": "Edit Notification",
    "SB-LABEL-NOTIFICATION-NAME": "Notification",
    "SB-LABEL-NOTIFICATION-START-TIME": "Start Time",
    "SB-LABEL-NOTIFICATION-END-TIME": "End Time",
    "SB-LABEL-NOTIFICATION-CONTENT": "Content",
    "SB-LABEL-NOTIFICATION-SEARCH-BY-DATE": "Search by date",
    "SB-LABEL-NOTIFICATION-TIMEZONE": "Standard Time Zone",
    "SB-LABEL-NOTIFICATION-INFORMATION": "Information",

    // Placeholder
    "CM-PLACEHOLDER-LOGIN-REQUIRED-INPUT": "Please enter your {item}",
    "CM-PLACEHOLDER-REQUIRED-INPUT": "Please enter {item}",
    "SB-PLACEHOLDER-SCRNUM": "Screening Number",
    "SB-PLACEHOLDER-ENRNUM": "Enrollment Number",
    "SB-PLACEHOLDER-CONTRACT-CASE-LEVEL": "Limit",
    "SB-PLACEHOLDER-AUTOMATIC-LOGOUT-TIME": "Automatic Logout Time",
    "SB-PLACEHOLDER-IMPORT-PDF-FILE": "Please select a PDF file",
    "SB-PLACEHOLDER-ID": "ID",
    "SB-PLACEHOLDER-CODE": "Code",
    "SB-PLACEHOLDER-LABEL": "Label",
    "SB-PLACEHOLDER-PROPERTY-DATA": "Property Data",
    "SB-PLACEHOLDER-STRATIFICATION-GROUP-NO": "Stratification group number",
    "SB-PLACEHOLDER-STRATIFICATION-NO": "Stratification number",
    "CM-PLACEHOLDER-IMPORT-EXCEL-FILE": "Please select a file",
    "CM-PLACEHOLDER-IMPORT-FILE": "Please select a file",
    "SB-PLACEHOLDER-STUDY-NAME": "Study Name",
    "SB-PLACEHOLDER-PROTOCOL": "Protocol No.",
    "SB-PLACEHOLDER-ALIAS": "Alternate Study Identifier",

    // Dropdown
    "CM-DROPDOWN-LANGUAGE": "English",
    "CM-DROPDOWN-LANGUAGE-JAPANESE": "日本語",
    "CM-DROPDOWN-LANGUAGE-ENGLISH": "English",
    "SB-DROPDOWN-NO-RESTRICTION": "No restriction",
    "CM-DROPDOWN-ALL": "All",
    "SB-DROPDOWN-ENABLE-STATUS": "Enabled",
    "SB-DROPDOWN-DISABLE-STATUS": "Disabled",
    "SB-DROPDOWN-CATEGORY-CRF": "CRF",
    "SB-DROPDOWN-CATEGORY-FREEZE": "FREEZE",
    "SB-DROPDOWN-CATEGORY-SDV": "SDV",
    "SB-DROPDOWN-CATEGORY-REVIEW": "REVIEW",
    "SB-DROPDOWN-CATEGORY-LOCK": "LOCK",
    "SB-DROPDOWN-CATEGORY-DB-LOCK": "DB-LOCK",
    "SB-DROPDOWN-CATEGORY-SIGNATURE": "SIGNATURE",
    "SB-DROPDOWN-CATEGORY-ECS-RE": "ECS-RE",
    "SB-DROPDOWN-CATEGORY-CASE": "CASE",
    "SB-DROPDOWN-CATEGORY-PAGE": "PAGE",
    "SB-DROPDOWN-CATEGORY-ADMIN": "ADMIN",
    "SB-DROPDOWN-CATEGORY-QUERY": "QUERY",
    "SB-DROPDOWN-CATEGORY-COMMENT": "COMMENT",
    "SB-DROPDOWN-CATEGORY-DATASET": "DATASET",
    "SB-DROPDOWN-CATEGORY-ASSIGNMENT": "ASSIGNMENT",
    "SB-DROPDOWN-CATEGORY-USER-REPORT": "USER-REPORT",
    "SB-DROPDOWN-CATEGORY-DELIVERY": "DELIVERY",
    "SB-DROPDOWN-CATEGORY-LIST-UPLOAD": "LIST-UPLOAD",
    "SB-DROPDOWN-CATEGORY-BLIND": "BLIND",
    "SB-DROPDOWN-CATEGORY-DATA-IMPORT": "DATA-IMPORT",
    "SB-DROPDOWN-CATEGORY-ECONSENT": "ECONSENT",
    "SB-DROPDOWN-CATEGORY-E-TRAINING": "E-TRAINING",
    "SB-DROPDOWN-CRF-GROUP-FOR-EDC": "CrfGroup for EDC",
    "SB-DROPDOWN-CRF-GROUP-FOR-EPRO": "CrfGroup for ePRO",
    "SB-DROPDOWN-CRF-GROUP-FOR-EPRO-AND-EDC": "CrfGroup for ePRO & EDC",
    "SB-DROPDOWN-PAGE-NORMAL-TYPE": "Normal Page",
    "SB-DROPDOWN-PAGE-REGISTRATION-TYPE": "Registration Page",
    "SB-DROPDOWN-BLOCK-NORMAL-TYPE": "Normal",
    "SB-DROPDOWN-BLOCK-FIXEDLINE-TYPE": "Fixed Line",
    "SB-DROPDOWN-BLOCK-LOGLINE-TYPE": "Log Line",
    "SB-DROPDOWN-ITEM-CHECKBOX": "Checkbox",
    "SB-DROPDOWN-ITEM-DATE-YYMMDD": "Date (YYYY-MM-DD)",
    "SB-DROPDOWN-ITEM-DATE-YYMMUN": "Date (YYYY-MM-UN)",
    "SB-DROPDOWN-ITEM-DATE-YYUNUN": "Date (YYYY-UN-UN)",
    "SB-DROPDOWN-ITEM-DATE-UNUNUN": "Date (UNUN-UN-UN)",
    "SB-DROPDOWN-ITEM-DROPDOWN": "Dropdown list",
    "SB-DROPDOWN-ITEM-LABEL": "Label",
    "SB-DROPDOWN-ITEM-RADIO-VERTICAL": "Radio (Vertical)",
    "SB-DROPDOWN-ITEM-RADIO-HORIZONTAL": "Radio (Horizontal)",
    "SB-DROPDOWN-ITEM-TEXT": "Text",
    "SB-DROPDOWN-ITEM-TEXTAREA": "Text area",
    "SB-DROPDOWN-ITEM-TIME-HHMM": "Time (HH:MM)",
    "SB-DROPDOWN-ITEM-TIME-HHUN": "Time (HH:UN)",
    "SB-DROPDOWN-ITEM-TIME-UNUN": "Time (UN:UN)",
    "SB-DROPDOWN-ITEM-ROWNO": "Row Number",
    "SB-DROPDOWN-ITEM-VAS": "VAS",
    "SB-DROPDOWN-PROPERTY-AUTOFILL": "AUTOFILL",
    "SB-DROPDOWN-PROPERTY-DEFAULT": "DEFAULT",
    "SB-DROPDOWN-PROPERTY-UNIT-UPPER": "UNIT_UPPER",
    "SB-DROPDOWN-PROPERTY-UNIT-BOTTOM": "UNIT_BOTTOM",
    "SB-DROPDOWN-PROPERTY-UNIT-LEFT": "UNIT_LEFT",
    "SB-DROPDOWN-PROPERTY-UNIT-RIGHT": "UNIT_RIGHT",
    "SB-DROPDOWN-PROPERTY-EXCLUDE": "EXCLUDE",
    "SB-DROPDOWN-PROPERTY-SCRNUM": "SCRNUM",
    "SB-DROPDOWN-PROPERTY-ENRNUM": "ENRNUM",
    "SB-DROPDOWN-PROPERTY-RANDOM-KEY": "RANDOM_KEY",
    "SB-DROPDOWN-PROPERTY-ARM-GENERATE": "ARM_GENERATE",
    "SB-DROPDOWN-PROPERTY-IP-GENERATE": "IP_GENERATE",
    "SB-DROPDOWN-PROPERTY-STRATIFICATION": "STRATIFICATION",
    "SB-DROPDOWN-PROPERTY-HIDDEN": "HIDDEN",
    "SB-DROPDOWN-PROPERTY-CONSENT-DATE": "CONSENT DATE",
    "SB-DROPDOWN-PROPERTY-EPRO-TRIGGER": "ePRO TRIGGER",
    "SB-DROPDOWN-PROPERTY-GET-DEVICE-DATE": "GET_DEVICE_DATA",
    "SB-DROPDOWN-SYSTEM-QUERY": "System Query",
    "SB-DROPDOWN-BLIND": "Blind",
    "SB-DROPDOWN-LINK-FORCED": "Link Forced",
    "SB-DROPDOWN-ALERT-MAIL": "Alert Email",
    "SB-DROPDOWN-BLIND-NOT-COMMIT": "Blind (Not Commit)",
    "SB-DROPDOWN-EPRO-SCHEDULE-SAME-DAY": "the same day as",
    "SB-DROPDOWN-EPRO-SCHEDULE-NEXT-DAY": "the next day after",
    "SB-DROPDOWN-EPRO-SCHEDULE-AFTER-NUMBER-OF-DAY": "number of days after",
    "CM-DROPDOWN-SECURITY-QUESTION-MOVIE": "What is your favorite movie?",
    "CM-DROPDOWN-SECURITY-QUESTION-BOOK": "What is your favorite book?",
    "CM-DROPDOWN-SECURITY-QUESTION-COLOR": "What is your favorite color?",
    "CM-DROPDOWN-SECURITY-QUESTION-FOOD": "What is your favorite food?",

    // Checkbox
    "CM-CHECKBOX-SELECT-ALL": "Select All",
    "CM-CHECKBOX-EMPTY": "Empty",
    "SB-CHECKBOX-DISPLAY-DELETED-RECORD": "Display deleted records",
    "SB-CHECKBOX-DISPLAY-DELETED-CRF": "Display deleted CRFs",
    "SB-CHECKBOX-CDMS": "CDMS",
    "SB-CHECKBOX-IWRS": "IWRS",
    "SB-CHECKBOX-PRO": "PRO",
    "CM-CHECKBOX-ECONSENT": "eConsent",
    "SB-CHECKBOX-UPGRADE-VERSION": "Upgrade version",
    "SB-CHECKBOX-CLONE-VERSION": "Clone version",
    "CM-CHECKBOX-SYSTEM-NOTIFICATION": "Don’t show this dialog again",
    "SB-CHECKBOX-EPRO-EMAIL-NOTIFICATION": "Email",
    "SB-CHECKBOX-EPRO-PUSH-NOTIFICATION": "Push",

    // Radio
    "SB-RADIO-YES-01": "YES",
    "SB-RADIO-NO-01": "NO",
    "SB-RADIO-YES-02": "Yes",
    "SB-RADIO-NO-02": "No",
    "SB-RADIO-AUTO-INPUT": "Auto Input",
    "SB-RADIO-USER-INPUT": "User Input",
    "CM-RADIO-NO-LIMITATION": "No Limitation",
    "CM-RADIO-STUDY": "Study",
    "CM-RADIO-SITE": "Site",
    "SB-RADIO-DATA-TYPE-CHARACTER": "Character",
    "SB-RADIO-DATA-TYPE-NUMBER": "Number",
    "SB-RADIO-READ-PERMISSION": "Read",
    "SB-RADIO-WRITE-PERMISSION": "Write",
    "SB-RADIO-STATIC-STRATIFICATION": "Static Stratification",
    "SB-RADIO-DYNAMIC-STRATIFICATION": "Dynamic Stratification",
    "SB-RADIO-YES-03": "Yes",
    "SB-RADIO-NO-03": "No",
    "SB-RADIO-IMMEDIATE-UBLIND": "Immediate Unblinding",
    "SB-RADIO-UNBLIND-REQUEST": "Request & Approve",
    "SB-RADIO-ARM": "ARM",
    "SB-RADIO-ARM-AND-IP-NO": "ARM+IP No.",

    // Hyperlink
    "CM-HYPERLINK-FORGOT-ID": "Forgot User ID",
    "CM-HYPERLINK-FORGOT-PWD": "Forgot Password",
    "SB-HYPERLINK-MORE": "More",
    "MSG-CM-ALERT-CHANGED-DATA": "Changes you made will not be saved.",
};

export default EN_ITEM_MESSAGE_CODE;
