const EN_MESSAGES_MESSAGE_CODE = {
    null: "",
    "": "",

    "MSG-CM-WRONG-ID-PWD":
        "The user ID or password entered is incorrect. \nPlease try again.",
    "MSG-CM-WRONG-PWD-5-TIMES":
        'Your account has been locked because you entered the wrong password 5 times. Please initialize the reset password from "Forgot password" or contact your system administrator.',
    "MSG-CM-WRONG-PASSWORD":
        "Incorrect password. (You have tried to access {x} time(s) out of the 5 permissible attempts.)",
    "MSG-CM-NON-EXISTED-EMAIL": "The e-mail has not been registered.",
    "MSG-CM-WRONG-SECRET-QUESTION":
        "The security question you selected is incorrect. Please try again.",
    "MSG-CM-SENT-NEW-PWD-VIA-EMAIL":
        "A new password has been sent to the e-mail.",
    "MSG-CM-SENT-ID-VIA-EMAIL":
        "The User ID has been sent to your email address.",
    "MSG-CM-INVALID-PASSWORD":
        "The password must be at least 8 characters long with an alphanumeric combination.",
    "MSG-CM-NOT-MATCH-PASSWORD": "Password does not match.",
    "MSG-CM-MATCH-PREVIOUS-PASSWORD":
        "Your new password must not match any of your previous passwords.",
    "MSG-CM-SETTING-PWD-SUCCESSFULLY": "Password setting has been completed.",
    "MSG-STU-CLOSE-STUDY":
        "If you close this study in the builder, the users will not be able to use the CDMS of this study. Are you sure you want to close this study?",
    "MSG-STU-OPEN-STUDY":
        "If you open this study in the builder, the users will be able to use the CDMS of this study. Are you sure you want to open this study?",
    "MSG-ETRA-DUPLICATED-FILE-NAME":
        "The file with the same name has been uploaded already. \nPlease choose a different file name.",
    "MSG-ETRA-INVALID-FILE-NAME":
        'Please set the file name without the symbols like {\\/:*?"<>+|,}.',
    "MSG-CM-DELETE-PARENT-ITEM":
        "If you delete this {parent_item}, all related {child_item}s will be deleted.\nAre you sure you want to delete this {parent_item}?",
    "MSG-CM-RESTORE-PARENT-ITEM":
        "If you restore this {parent_item}, all related {child_item}s will be restored.\nAre you sure you want to restore this {parent_item}?",
    "MSG-CM-DELETE-ALL-CHILD-ITEM":
        "If you delete all {child_item}s, the {parent_item} will also be deleted.\nAre you sure you want to delete this {child_item}?",
    "MSG-SCHE-NOT-SET-SCHEDULE-YET": "The schedule hasn't been set.",
    "MSG-ENT-SCHE-CYCLE-ID-NOT-EXIST":
        "Row {n} of {sheet_name} Sheet: Cycle 「cycle_id」 is not existing.",
    "MSG-ENT-SCHE-CYCLE-ID-VISIT-ID-NOT-EXIST":
        "Row {n} of {sheet_name} Sheet: 「cycle_id | visit_id」 is not existing.",
    "MSG-ENT-SCHE-CYCLE-ID-VISIT-ID-PAGE-ID-NOT-EXIST":
        "Row {n} of {sheet_name} Sheet: 「cycle_id |visit_id | page_id」 is not existing.",
    "MSG-ENT-SCHE-PAGE-ID-NOT-ASSIGN-CYCLE-ID-VISIT-ID":
        "Row {n} of {sheet_name} Sheet: 「page_id」 is not existing in 「cycle_id |visit_id」.",
    "MSG-PAG-SET-NORMAL-TYPE-FOR-PAGE-WITH-SCRNUM-ITEM":
        "The page type cannot be changed because the Screening number has already been set for this page.",
    "MSG-PAG-SET-NORMAL-TYPE-FOR-PAGE-WITH-CONSENT-DATE-ITEM":
        "The page type cannot be changed because the Consent date has already been set for this page.",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-SCRNUM-ITEM":
        "The block type cannot be changed because the Screening number has already been set for this block.",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-ENRNUM-ITEM":
        "The block type cannot be changed because the Enrollment number has already been set for this block.",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-STRATIFICATION-ITEM":
        "The block type cannot be changed because the STRATIFICATION has already been set for Item of this block.",
    "MSG-BLO-SET-TABLE-TYPE-FOR-BLOCK-WITH-EPRO-TRIGGER-ITEM":
        "The block type cannot be changed because the ePRO_TRIGGER has already been set for Item of this block.",
    "MSG-ITE-DATA-LENGTH-LESS-THAN-DB-VALUE":
        "The data length of the item must be greater than or equal to length of DB Value in CodeList.",
    "MSG-ITE-CONFIRM-DELETE-DATA-DUE-TO-LAYOUT-CHANGE":
        "The input data of CDMS may be deleted due to the layout change. Are you sure you want to change the layout?",
    "MSG-ITE-DIFFERENT-DATA-TYPE-BETWEEN-CODELIST-AND-ITEM":
        "The data type of item and CodeList are different.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-ENRNUM-ITEM":
        "The layout of the item cannot be changed because the enrollment number is already set for this item.",
    "MSG-ITE-DUPLICATED-ITEM-ID-IN-THE-SAME-DOMAIN":
        "The same ID cannot be set in the same Domain.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-SCRNUM-ITEM":
        "The layout of the item cannot be changed because the screening number is already set for this item.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-ARM-GENERATE-ITEM":
        "The layout cannot be set for this item because the ARM_GENERATE is already set for it.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-IP-GENERATE-ITEM":
        "The layout cannot be set for this item because the IP_GENERATE is already set for it.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-RANDOM-KEY-ITEM":
        "The layout cannot be set to Label/Row No because the RANDOM_KEY is already set for this item.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-STRATIFICATION-ITEM":
        "The layout cannot be set to Label/Date/Time because the STRATIFICATION is already set for this item.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-CONSENT-DATE-ITEM":
        "The layout of the item cannot be changed because the CONSENT DATE is already set for this item.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-DATE-FUTURE-ITEM":
        "The layout of the item cannot be changed because the DATE_FUTURE is already set for this item.",
    "MSG-ITE-VAS-MAX-VALUE-LESS-THAN-MIN-VALUE": "Please enter a maximum value that is greater than the minimum value.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-GET-DEVICE-DATA-ITEM":
        "The layout cannot be set for this item because the GET_DEVICE_DATA is already set for it.",
    "MSG-ITE-CHANGE-INVALID-LAYOUT-FOR-EPRO-TRIGGER-ITEM":
        "The layout of the item cannot be changed because the ePRO TRIGGER is already set for this item.",
    "MSG-CM-IMPORT-MANY-SHEET-MIN-VALUE-GREATER-THAN-MAX-VALUE":
        "Row {n} of {sheet_name} Sheet: the maximum value of the item must be greater than the minimum value of it.",
    "MSG-PRO-SET-INVALID-PROPERTY-FOR-NORMAL-PAGE-ITEM":
        "This Property cannot be set to items of a Normal Page.",
    "MSG-PRO-SET-EXCLUDE-AND-SCRNUM FOR-SAME-ITEM":
        "EXCLUDE and SCRNUM cannot be set to a same item.",
    "MSG-PRO-SET-SCRNUM-FOR-NON-NORMAL-BLOCK-ITEM":
        "SCRNUM can only be set to items of Normal Block.",
    "MSG-PRO-SET-ENRNUM-AND-SCRNUM FOR-SAME-ITEM":
        "ENRNUM and SCRNUM cannot be set to a same item.",
    "MSG-PRO-SET-MULTIPLE-ENRNUM-ITEM-FOR-A-PAGE":
        "Multiple ENRNUM cannot be set to a same page.",
    "MSG-PRO-SET-ENRNUM-FOR-NON-TEXT-ITEM":
        "ENRNUM can only be set to TEXT item.",
    "MSG-PRO-SET-ENRNUM-FOR-NON-NORMAL-BLOCK-ITEM":
        "ENRNUM can only be set to items of Normal Block.",
    "MSG-ENT-PRO-MORE-THAN-ONE-REGISTRATION-PAGE":
        "Registration Page type has been set to more than one page.",
    "MSG-PRO-SET-MULTIPLE-SCRNUM-ITEM-FOR-A-PAGE":
        "Multiple SCRNUMs cannot be set on the same page.",
    "MSG-PRO-SET-SCRNUM-FOR-NON-TEXT-ITEM":
        "SCRNUM can only be set to TEXT item.",
    "MSG-PRO-SET-INVALID-PROPERTY-FOR-LABEL-AND-ROWNO-ITEM":
        "This Property cannot be set to LABEL or ROW NO items.",
    "MSG-PRO-SET-ARM-IP-GENERATE-AND-GET-DEVICE-DATA-FOR-ITEM-WITH-NON-TEXT-NON-TEXTAREA-LAYOUT":
        "This Property can only be set to TEXT or TEXT AREA items.",
    "MSG-PRO-SET-STRATIFICATION-FOR-ITEM-WITH-LABEL-DATE-TIME-LAYOUT":
        "This Property cannot be set to LABEL, DATE or TIME items.",
    "MSG-PRO-SET-MULTIPLE-RANDOM-KEY-ITEM-FOR-A-STUDY":
        "RANDOM_KEY cannot be set to more than one item.",
    "MSG-PRO-SET-STRATIFICATION-GROUP-NO-FOR-MULTIPLE-ITEM-IN-A-STUDY":
        "One Stratification Group No. can only be set to one item.",
    "MSG-PRO-SET-MULTIPLE-STRATIFICATION-FOR-AN-ITEM":
        "Multiple Stratification Group No. cannot be set to a same item.",
    "MSG-PRO-SET-MULTIPLE-RANDOM-KEY-FOR-AN-ITEM":
        "Multiple RANDOM_KEY cannot be set to a same item.",
    "MSG-PRO-SET-ARM-IP-GENERATE-FOR-AN-ITEM":
        "ARM_GENERATE and IP_GENERATE cannot be set to a same item.",
    "MSG-PRO-SET-HIDDEN-AND-SCRNUM-FOR-AN-ITEM":
        "HIDDEN and SCRNUM cannot be set to a same item.",
    "MSG-PRO-SET-HIDDEN-AND-ENRNUM-FOR-AN-ITEM":
        "HIDDEN and ENRNUM cannot be set to a same item.",
    "MSG-PRO-SET-STRATIFICATION-FOR-NON-NORMAL-BLOCK-ITEM":
        "STRATIFICATION can only be set to items of Normal Block.",
    "MSG-PRO-SET-CONSENT-DATE-FOR-NON-DATE-ITEM":
        "CONSENT DATE can only be set to DATE item.",
    "MSG-PRO-SET-EPRO-TRIGGER-FOR-NON-FULL-DATE-ITEM":
        "ePRO TRIGGER can only be set to Date (YYYY/MM/DD) item.",
    "MSG-PRO-SET-EPRO-TRIGGER-FOR-NON-NORMAL-BLOCK-ITEM":
        "ePRO TRIGGER can only be set to items of Normal Block.",
    "MSG-PRO-SET-MUTIPLE-EPRO-TRIGGER-ITEM-FOR-A-PAGE":
        "Multiple ePRO TRIGGER cannot be set on the same page.",
    "MSG-PRO-SET-DATE-FUTURE-FOR-NON-DATE-ITEM":
        "DATE FUTURE can only be set to DATE item.",
    "MSG-COD-WARNING-CODELIST-IN-USE-FOR-AN-ITEM-NOT-BE-DELETED":
        "This CodeList cannot be deleted because it has been set to an Item.",
    "MSG-COD-DB-VALUE-GREATER-THAN-DATA-LENGTH":
        "The length of DB Value must be less than or equal to data length of the item.",
    "MSG-COD-INVALID-CODELIST-VALUE-FOR-NUMERIC-ITEM":
        "This CodeList is being set to items with numeric data type.",
    "MSG-ECS-EMPTY-TARGET":
        "Please enter at least one of Visit ID, Page ID, Block ID, Item ID or Line.",
    "MSG-CM-IMPORT-ECS-EMPTY-TARGET":
        "Row {n}: Please enter at least one of Visit ID, Page ID, Block ID, Item ID or Line.",
    "MSG-VER-DUPLICATED-VERSION-PAIR":
        "This pair of CRF Verion and ECS Version has already been in use.",
    "MSG-CM-END-DATE-GREATER-THAN-START-DATE":
        "End Date must be greater than Start Date.",
    "MSG-ADM-RELEASE-SUCCESS": "The study has been released successfully.",
    "MSG-ADM-RELEASE-CONFIRMATION": "Do you really want to release this study?",
    "MSG-ADM-CLONE-SUCCESS": "{newStudyId} has been cloned successfully.",
    "MSG-ADM-WARNING-NOT-DELETE-USER-ASSIGNED-TO-STUDY":
        "This user cannot be deleted because it is assigned to a study",
    "MSG-ARM-DELETE-CONFIRMATION": "Are you sure you want to delete this ARM?",
    "MSG-CM-RESET-PWD-CONFIRMATION":
        "Are you sure you want to initialize the password?",
    "MSG-CM-RESET-PASSWORD-SUCCESSFULLY":
        "The password has been initialized.\nA new password has been sent to the email.",
    "MSG-CM-IMPORT-ONE-SHEET-INVALID-VALUE":
        "Row {n}: the input value of {item} is invalid.",
    "MSG-CM-IMPORT-ONE-SHEET-OVER-MAXLENGTH":
        "Row {n}: the length of {item} must be less than or equal to {max_length} characters.",
    "MSG-CM-IMPORT-ONE-SHEET-EMPTY-VALUE": "Row {n}: {item} must not be empty.",
    "MSG-CM-IMPORT-ONE-SHEET-DUPLICATED-VALUE":
        "Row {n}: the input {item} has already been duplicated.",
    "MSG-CM-IMPORT-ONE-SHEET-NON-EXISTED-VERSION":
        "Row {n}: the input version is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-EMPTY-VALUE":
        "Row {n} of {sheet_name} Sheet: {item} must not be empty.",
    "MSG-CM-IMPORT-MANY-SHEET-INVALID-VALUE":
        "Row {n} of {sheet_name} Sheet: the input value of {item} is invalid.",
    "MSG-CM-IMPORT-MANY-SHEET-OVER-MAXLENGTH":
        "Row {n} of {sheet_name} Sheet: the length of {item} must be less than or equal to {max_length} characters.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-VALUE":
        "Row {n} of {sheet_name} Sheet: the input {item} is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-DUPLICATED-VALUE":
        "Row {n} of {sheet_name} Sheet: the input data has already been duplicated.",
    "MSG-CM-IMPORT-MANY-SHEET-DATA-LENGTH-LESS-THAN-DB-VALUE":
        "Row {n} of {sheet_name} Sheet: the data length of the item must be greater than or equal to length of DB Value in CodeList.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-GROUP-ID":
        "Row {n} of {sheet_name} Sheet: {item} is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-BLOCK-ID":
        "Row {n} of {sheet_name} Sheet: {block_id} of{item}is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-CYCLE-ID":
        "Row {n} of {sheet_name} Sheet: Cycle 「cycle_id」 is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-CYCLE-VISIT-ID":
        "Row {n} of {sheet_name} Sheet: 「cycle_id | visit_id」 is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-CYCLE-VISIT-PAGE-ID":
        "Row {n} of {sheet_name} Sheet: 「cycle_id |visit_id | page_id」 is not existing.",
    "MSG-CM-IMPORT-MANY-SHEET-NON-EXISTED-PAGE-ID":
        "Row {n} of {sheet_name} Sheet: 「page_id」 is not existing in 「cycle_id |visit_id」.",
    "MSG-CM-IMPORT-SUCCESSFULLY": "Data has been uploaded successfully.",
    "MSG-CM-IMPORT-NOT-SELECT-FILE": "Please select a file.",
    "MSG-CM-DUPLICATED-DATA": "The input {item} has already been used.",
    "MSG-CM-NO-DATA": "No data",
    "MSG-CM-INVALID-DATA": "You have entered invalid data. Please try again.",
    "MSG-CM-REQUIRED-DATA-INPUT": "Please enter {item}.",
    "MSG-CM-SERVER-ERROR":
        "A server error occurred. \nPlease contact the administrator.",
    "MSG-CM-SAVE-DATA-SUCCESSFULLY": "Data has been saved.",
    "MSG-CM-LOST-NETWORK-CONNECTION": "Please check your network connection.",
    "MSG-CM-API-TIMEOUT": "A timeout occurred.",
    "MSG-CM-IMPORT-NOT-SELECT-EXCEL-FILE": "Please select an Excel file.",
    "MSG-IMPORT-NOT-SELECT-PDF-FILE": "Please select a PDF file.",
    "MSG-CM-IMPORT-NON-EXISTED-FILE": "The selected file is not existing.",
    "MSG-CM-IMPORT-EXCEED-FILE-SIZE":
        "The file is too large. The maximum allowed file size is {xx} MB.",
    "MSG-CM-IMPORT-BLANK-FILE": "The file is blank. Please select another file.",
    "MSG-ETRA-FILE-NAME-EXCEED-MAXLENGTH":
        "Please set the file name to 80 characters or less.",
    "MSG-CM-ALERT-CHANGED-DATA": "Changes you made will not be saved.",
    "MSG-CM-DELETE-CONFIRMATION":
        "Are you sure you want to delete this {item}?",
    "MSG-CM-RESTORE-CONFIRMATION":
        "Are you sure you want to restore this {item}?",
    "MSG-CM-ALERT-NOT-SAVE-DATA":
        "You cannot save because no data change has been made.",
    "MSG-CM-REQUEST-REFRESH-PAGE":
        "Configuration settings has been changed. Please refresh the page.",
    "MSG-COD-REQUIRED-CODELIST-VALUE":
        "Please enter one or more code list values.",
    "MSG-CM-DUPLICATED-EMAIL":
        "The email address you entered is already registered.",
    "MSG-CM-REQUIRED-SECURITY-ANSWER":
        "Please enter the answer for the security question.",
    "MSG-CM-INCORRECT-SECURITY-ANSWER": "The security answers is not correct.",
    "MSG-ADM-WARNING-NOT-RESTORE-USER-DELETED-BY-SUPER-ADMIN":
        "This user cannot be restored because of being deleted by Super Admin",
    "MSG-CM-IMPORT-INCORRECT-FILE-FORMAT": "The file format is not correct.",
    "MSG-LOGIN-REQUIRED-DATA-INPUT": "Please enter your {item}.",
    "MSB-EDC-REQUIRED-MAX-LIMIT-PER-STUDY":
        "Please set the maximum limit per study.",
    "MSB-EDC-REQUIRED-MAX-LIMIT-PER-SITE":
        "Please set the maximum limit per site.",
};
export default EN_MESSAGES_MESSAGE_CODE;
